import React, { useMemo, useState } from 'react';
import { CRow, CCol, CCard, CCardBody, CCardHeader, CCardTitle, CLink, CButton } from '@coreui/react';
import _t from 'counterpart';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { IAffiliateCommissionLedger } from '../types';
import { coalesce, formatCurrency, formatDateTime } from '../helpers';
import Loading from '../components/Loading';
import PayoutStatusBadge from './PayoutSatusBadge';
import PageLayout from '../components/PageLayout';
import Description from '../components/Description';
import PaginationTable from '../components/PaginationTable';
import { getPayoutRequest } from '../services/BackendService';
import Error from '../components/Error';

const PayoutRequestPage = () => {
	const [page, setPage] = useState<number>(1);

	const { id } = useParams<{ id: string }>();
	const history = useHistory();
	const handleDetailsClicked = (id: number) => {
		history.push(`/commission-ledgers/${id}`);
	};

	const limit = 10;
	const offset = Number(page) > 0 ? Number(page) * limit - limit : 0;

	const onPageChanged = (page: number) => {
		setPage(page);
	};

	const payoutRequestQuery = useQuery(['payout-request', offset, id], () =>
		getPayoutRequest(Number(id), limit, offset)
	);
	const { isLoading, data, refetch, isError } = payoutRequestQuery;

	const fields = useMemo(
		() => [
			{ key: 'id', label: 'ID', sorter: false },
			{ key: 'referral', label: _t('commission.referral'), sorter: false },
			{ key: 'commissionType', label: _t('commission.commission-type'), sorter: false },
			{ key: 'actionType', label: _t('commission.action-type'), sorter: false },
			{ key: 'actionDetails', label: _t('commission.action-details'), sorter: false },
			{ key: 'commissionAmount', label: _t('commission.amount'), sorter: false },
			{ key: 'actionTimestamp', label: _t('global.date'), sorter: false },
		],
		[]
	);

	const scopedSlots = useMemo(
		() => ({
			id: ({ id }: IAffiliateCommissionLedger) => <td>{id}</td>,
			referral: ({ referral }: IAffiliateCommissionLedger) => (
				<td>
					<CLink href={`/referrals/${referral.id}`}>{referral.name}</CLink>
				</td>
			),
			actionTimestamp: ({ actionTimestamp }: IAffiliateCommissionLedger) => <td>{formatDateTime(actionTimestamp)}</td>,
			commissionAmount: ({ commissionAmount, correctedCommissionAmount, currency }: IAffiliateCommissionLedger) => (
				<td>{formatCurrency(coalesce(correctedCommissionAmount, commissionAmount), currency)}</td>
			),
			correctedCommissionAmount: ({ correctedCommissionAmount, currency }: IAffiliateCommissionLedger) => (
				<td>{correctedCommissionAmount ? formatCurrency(correctedCommissionAmount, currency) : '-'}</td>
			),
		}),
		[data?.payoutRequest.approvedAt]
	);

	if (isLoading || !data) {
		return <Loading />;
	}

	if (isError) {
		return <Error onRetry={refetch} />;
	}

	const { payoutRequest, commissions, commissionsCount, totalPayoutAmount } = data;
	const { payoutWalletId, requestedAt, approvedAt, rejectedAt, rejectReason } = payoutRequest;

	return (
		<PageLayout
			title={_t('commission.payout-request')}
			titleAppend={
				<div className="d-flex align-items-center justify-content-between" style={{ height: '36px' }}>
					<PayoutStatusBadge approvedAt={approvedAt} rejectedAt={rejectedAt} />
				</div>
			}
		>
			<CRow>
				<CCol md={12}>
					<CCard>
						<CCardBody>
							<CRow>
								<Description title={_t('commission.payout-wallet')}>{payoutWalletId}</Description>
								<Description title={_t('commission.total-payout-amount')}>
									{formatCurrency(totalPayoutAmount)}
								</Description>
							</CRow>
							<CRow>
								<Description title={_t('commission.requested-at')}>{formatDateTime(requestedAt)}</Description>
								<Description title={_t('commission.action-date')}>
									{coalesce(approvedAt, rejectedAt) ? formatDateTime(coalesce(approvedAt, rejectedAt)) : '-'}
								</Description>
							</CRow>
							<CRow></CRow>
							<CRow>
								<Description title={_t('commission.number-of-commissions')}>{commissionsCount}</Description>
							</CRow>
						</CCardBody>
					</CCard>
				</CCol>
			</CRow>
			{rejectReason && (
				<CRow>
					<CCol>
						<CCard>
							<CCardHeader>
								<CCardTitle>{_t('commission.reject-reason')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<p>{rejectReason}</p>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			)}

			{rejectedAt !== null && commissionsCount === 0 && (
				<CRow>
					<CCol>
						<CCard>
							<CCardHeader>
								<CCardTitle>{_t('global.info')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<p>{_t('commission.rejected-request-info')}</p>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			)}

			{!(rejectedAt !== null && commissionsCount === 0) && (
				<CRow>
					<CCol md={12}>
						<CCard>
							<CCardHeader className="d-flex justify-content-between align-items-center">
								<CCardTitle>{_t('commission.commission-ledger-records')}</CCardTitle>
							</CCardHeader>
							<CCardBody>
								<PaginationTable
									data={commissions ?? []}
									tableFields={fields}
									scopedSlots={scopedSlots}
									loading={isLoading}
									pages={data.commissionsCount ? Math.ceil(data.commissionsCount / Number(limit)) : 1}
									activePage={page}
									onPageChanged={onPageChanged}
									pagination
								/>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			)}
		</PageLayout>
	);
};

export default PayoutRequestPage;
